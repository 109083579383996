import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';

export default function VerificationForm(props) {
  const { show, setShow, handleSubmit, type, codeValidity, resend, translations } = props;
  const renderer = ({ hours, minutes, seconds, completed, api }) => {
    if (codeValidity > Date.now() && api.isStopped()) {
      api.start();
    }
    if (completed)
      return (
        <button
          onClick={resend}
          className='modal-button-light modals-btn w-full'
        >
          {translations.resend_verification_code}
        </button>
      );
    return (
      <div className='modal-button-light modals-btn w-full !text-[#74808E]'>
        {translations.resend_verification_code_in} {zeroPad(minutes)}:{zeroPad(seconds)}
      </div>
    );
  };
  return (
    <div
      className='sign__verfication_modal place-content-center'
      style={{ display: show ? 'grid' : 'none' }}
    >
      <div className='grid grid-cols-1 justify-items-center gap-4  modals-container'>
        <button
          className='absolute top-2 right-2 text-black'
          id='verification-modal_close-btn'
          type='button'
          onClick={() => setShow(false)}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M6 18L18 6M6 6l12 12'
            />
          </svg>
        </button>

        <form
          className='flex flex-col w-full'
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e.target[0].value);
          }}
        >
          <h4 className='modal-headers'>
            {type === 'phone' ? translations.confirm_phone_number : translations.confirm_email_address}
          </h4>

          <p className='mb-1 sign__input-modal-paragraph !text-center'>
          {type == 'phone' ? translations.please_provide_the_otp_code : translations.please_provide_the_verification_code }
          </p>
          <div className='sign_input-container my-3 !w-full'>
            <input
              type='text'
              name='otp-code'
              className={`editInput w-full placeholder:text-[#BDC1C5] border border-[#B0CAE8] p-2 focus:outline-none  rounded  `}
              placeholder={type == 'phone' ? translations.otp_code : translations.verification_code}
            />
          </div>
          <div className='mt-2 grid grid-cols-1 gap-2 w-full'>
            <button
              type='submit'
              className='modal-button-dark modals-btn w-full'
            >
              {translations.confirm}
            </button>
            <p className='mb-1 sign__input-modal-paragraph form-text-success text-left !text-base'>
              {codeValidity && (
                <Countdown date={codeValidity} renderer={renderer} autoStart />
              )}
            </p>
          </div>
        </form>
        {/* <p className='!text-[#74808E] text-center !w-[70%] !text-xs'>
          We do not own private keys and cannot access your funds
        </p> */}
      </div>
    </div>
  );
}
