import { Controller } from "stimulus";

export default class extends Controller {
  // static targets = ['facebook', 'twitter', 'whatsapp', 'reddit', 'copy'];

  shareCollection(e) {
    window.show_modal('#share_modal');
    const { id } = e.params;
    const linkUrl = window.location.origin + `/collections/${id}`
    const link = encodeURI(linkUrl);
    const msg = 'Hey, take a look';
    const copy = document.getElementById('copy')

    document.getElementById('fb').href = `https://www.facebook.com/sharer/sharer.php?u=${link}`;
    document.getElementById('twitter').href = `https://twitter.com/intent/tweet/?&url=${link}&text=${msg}&hashtags=mola`;
    // this.linkedinTarget.href = `https://www.linkedin.com/sharing/share-offsite/?url=${link}`;
    document.getElementById('reddit').href = `https://www.reddit.com/submit?url=${link}`;
    document.getElementById('whatsapp').href = `https://api.whatsapp.com/send?text=${link}`;
    copy.value = link;
    copy.disabled = true;
  }

  copyBtn() {
    const copyInput = document.querySelector('#copy');
    copyInput.disabled = false;
    copyInput.select();
    document.execCommand('copy');
    copyInput.disabled = true;
  }
}
