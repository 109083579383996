import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["price", "pricehidden", "pricehiddenIDR", "priceEUR", "pricehiddenEUR"]

   initialize(){
     this.validateAddress();
   }

  privacyPolicy(){
    const spinner = document.getElementById('spin');
    const iframe = document.getElementById('myframe');
    spinner.style.display = 'block'
    iframe.classList.add('hidden');
    setTimeout(() => {
      spinner.style.display = 'none'
      iframe.classList.remove('hidden');
    }, 5000);    
  }

  quantityChange(e){
    // console.log(+this.priceTarget.value)
    let quantity = e.target.value ? e.target.value : 1
    if(!e.target.classList.contains('is_gala')) {
      if(String(quantity).length > 2){
        quantity = Number(quantity.slice(0,2));
        e.target.value = quantity
      }
      if(+quantity > 10) {
        quantity = 10;
        e.target.value = 10;
      }
    } else {
      const maxVal = e.target.getAttribute('max');
      if(+quantity > +maxVal){
        quantity = Number(maxVal);
        e.target.value = quantity
      }
    }
    this.priceTarget.value = +quantity * this.pricehiddenTarget.value
    if (this.priceIDRTarget) {
     this.priceIDRTarget.value = Math.round(parseInt(quantity) * this.pricehiddenIDRTarget.value * 100, 2) / 100
    }
    if (this.priceEURTarget) {
      this.priceEURTarget.value = +quantity * this.pricehiddenEURTarget.value
    }
  }

  checkSubmit(e){
      const submit = document.getElementById('submit');
      if(e.target.value.length > 0){
        submit.disabled = false;
      } else {
        submit.disabled = true;
      }

    }

  validateAddress(){
    const addressOne = document.querySelector('[name="user[shipping_address_line_one]"]');
    const submit = document.getElementById('submit');
    if(addressOne.value.length < 1){
      submit.disabled = true;
    }
  }

  requireVatOrCode(){
    const vatNumber = document.getElementById('vat-number');
    const taxCode = document.getElementById('tax-code');
    const validationMessage = document.getElementById('validationMessage');
    if(vatNumber.value.length < 1 && taxCode.value.length < 1){
      vatNumber.required = true;
      taxCode.required = true;
      vatNumber.setCustomValidity(validationMessage.value);
      taxCode.setCustomValidity(validationMessage.value);

    } else {
      vatNumber.required = false;
      taxCode.required = false;
    }
  }
  
  requireVat(e){
    this.validateVatInput()
  }

  requireCode(){
    this.validateVatInput()
  }

  validateVatInput(){
    const vatNumber = document.getElementById('vat-number');
    const taxCode = document.getElementById('tax-code');
    const validationMessage = document.getElementById('validationMessage');
    if(taxCode.value.length >= 1 || vatNumber.value.length >=1){
      vatNumber.required = false;
      taxCode.required = false;
      taxCode.setCustomValidity('');
      vatNumber.setCustomValidity('');
    } else {
      vatNumber.required = true;
      taxCode.required = true;
      vatNumber.setCustomValidity(validationMessage.value);
      taxCode.setCustomValidity(validationMessage.value);
    }
  }
  

}
