import axios from "axios";
import { IAssetData } from "../helpers/types";

export async function createUserSession(address: string, token: string, balance: string, wallet: string): Promise<IAssetData[]> {
  const config = {
    headers: {
      'X-CSRF-TOKEN': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }

  const resp = await axios.post(`/sessions`, {address: address, balance: balance, wallet: wallet}, config)
    .then((response) => {
      return resp
    })
    .catch(err => {
      console.log("User Session Create Error", err)
    })
  return resp;
}

export async function destroyUserBanner(token: string, id: number): Promise<IAssetData[]> {
  const config = {
    headers: {
      'X-CSRF-TOKEN': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }

  const resp = await axios.delete(`/users/${id}/remove_user_banner`, config)
    .then((response) => {
      if(response.status === 200) {
        window.location.reload();
      }
    })
    .catch(err => {
      console.log("There is an error while destroying user banner", err)
    })
  return resp;
}

export async function destroyUserAttachment(token: string, id: number): Promise<IAssetData[]> {
  const config = {
    headers: {
      'X-CSRF-TOKEN': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }

  const resp = await axios.delete(`/users/${id}/remove_user_attachment`, config)
    .then((response) => {
       if(response.status === 200) {
        window.location.reload();
      }
    })
    .catch(err => {
      console.log("There is an error while destroying user attachment", err)
    })
  return resp;
}

export async function destroyUserSession(address: string, token: string): Promise<IAssetData[]> {
  const config = {
    data: {},
    headers: {
      'X-CSRF-TOKEN': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }

  const resp = axios.delete(`/sessions/${address}`, config)
    .then(response => response)
    .catch(err => console.log("Session Error: ", err))

  return resp
}

function getHeader(token: string) {
  return  {
    headers: {
      'X-CSRF-TOKEN': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }
}

function getLikedCollections() {
  const liked = localStorage.getItem('likedCollections');
  return liked ? JSON.parse(liked) : [];
}

function setLikedCollections(collections) {
  localStorage.setItem('likedCollections', JSON.stringify(collections));
}

export async function likeWithoutUser(collectionId: string, token: string, translations: string): Promise<IAssetData[]> {
  const resp = axios.post(`/like_without_user`, {collection_id: collectionId}, getHeader(token))
    .then((response) => {
      const likedCollections = getLikedCollections();
      likedCollections.push(collectionId);
      setLikedCollections(likedCollections);
      toastr.success(translations.like_success, { messageClass: 'toast-message'})
    }).catch((err) => {
      toastr.error(translations.something_wrong, { messageClass: 'toast-message'})
    });
  return resp;
}

export async function unlikeWithoutUser(collectionId: string, token: string, translations: string): Promise<IAssetData[]> {
  const resp = axios.post(`/unlike_without_user`, {collection_id: collectionId}, getHeader(token))
    .then((response) => {
      const likedCollections = getLikedCollections();
      const index = likedCollections.indexOf(collectionId);
      if (index > -1) {
        likedCollections.splice(index, 1);
      }
      setLikedCollections(likedCollections);
      toastr.success(translations.unlike_success);
    }).catch((err) => {
      toastr.error(translations.something_wrong);
    });
  return resp;
}

export async function userLike(address: string, collectionId: string, token: string, translations: string): Promise<IAssetData[]> {
  const resp = axios.post(`/users/like`, {id: address, collection_id: collectionId}, getHeader(token))
    .then((response) => {
      toastr.success(translations.like_success)
  }).catch((err) => {
      toastr.error(translations.something_wrong)
  })
  return resp
}

export async function userUnlike(address: string, collectionId: string, token: string, translations: string): Promise<IAssetData[]> {
  const resp = axios.post(`/users/unlike`, {id: address, collection_id: collectionId}, getHeader(token))
    .then((response) => {
      toastr.success(translations.unlike_success)
    }).catch((err) => {
        toastr.error(translations.something_wrong)
    })
  return resp
}

export async function verifyPhoneNumber(params: {phone_number: string }, token: string) {
  const response = await axios.post("/verify/verify_phone_number", {user: { ...params }}, getHeader(token))
  return response.data
}

export async function verifyPhoneNumberOTP(params: {phone_number: string}, token: string, code: string) {
  const response = await axios.put(`/verify/verify_twilio_code?otp_code=${code}`, {user: { ...params }}, getHeader(token))
  return response.data
}

export async function sendEmailVerificationCode(params: {email: string}, token: string) {
  const response = await axios.post("/verify/send_verification_email_code", {user: { ...params }}, getHeader(token))
  return response.data
}

export async function verifyEmailVerificationCode(params: {email: string, isMolaSub: boolean}, code: string, token: string) {
  const response = await axios.put(`/verify/verify_email_verification_code?verification_code=${code}`, {user: { ...params }}, getHeader(token))
  return response.data
}

export async function updateUser(params: object, token: string, id: number) {
  const header = {
    headers: {
      'Accept': '*/*',
      'X-CSRF-TOKEN': token,
      "Content-Type": "multipart/form-data",
      processData: "false"
    }
  }

  const response = await axios.put(`/users/${id}`, params, header)
  return response.data
}
export async function updateUserBanner(params: object, token: string, id: number, ) {
  const header = {
    headers: {
      'Accept': '*/*',
      'X-CSRF-TOKEN': token,
      "Content-Type": "multipart/form-data",
      processData: "false"
    }
  }

  const response = await axios.put(`/users/${id}/update_crop_images`, params, header)
  return response.data
}