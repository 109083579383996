const ticketTypePrefix = {
  "TC-A": "Tribuna Coperta A",
  "TC-B": "Tribuna Coperta B",
  "TC-C": "Tribuna Coperta C",
  "TC-D": "Tribuna Coperta D",
  "TC-E": "Tribuna Coperta E",
  "TC-F": "Tribuna Coperta F",
  "TS-A": "Tribuna Stampa A",
  "TS-B": "Tribuna Stampa B",
  "CO-A": "Curva Ospiti A",
  "CO-B": "Curva Ospiti B",
  "CO-C": "Curva Ospiti C",
  "CC-A": "Curva Como A",
  "CC-B": "Curva Como B",
  "CC-C": "Curva Como C",
  "CC-D": "Curva Como D",
  "CC-E": "Curva Como E",
  "CC-F": "Curva Como F",
  "DI-A": "Distinti A",
  "DI-B": "Distinti B",
}

window.seatSelection = {
  ticketName: "",
  selectedSeats: [],
  maximumSelect: 0,
  reservedSeats: [],
  reservedStands: {},

  getTicketName() {
    return this.ticketName;
  },

  setTicketName(newName) {
    this.ticketName = newName;
    window.ticketType.innerText = newName;
    // window.Board.draw();
  },

  setReservedSeats(seats) {
    this.reservedSeats = seats;
    this.reservedStands = {};
    this.reservedSeats.forEach((text) => {
      if (text.startsWith("stand")) {
        this.reservedStands[text] = this.reservedStands[text]
        ? this.reservedStands[text] + 1
        : 1;
      }
    })
  },

  setMaximumSelection(value) {
    this.maximumSelection = value;
    window.totalToSelect.innerText = `o/${value}`;
  },

  async setSelectedSeats(selectedSeats) {
    this.selectedSeats = selectedSeats;

    if (this.selectedSeats.length > 0) {
      window.checkoutBtn.classList.remove("disabled");
    }
  },

  async submitSeats() {
    window.checkoutModal.innerText = '';
    window.checkoutModal.classList.add('hidden');
    window.checkoutBtn.classList.add('disabled');
    let token = document.querySelector("[name=csrf-token]");
    const saveSeat = await fetch("/event-ticket-seats#reserved_seats", {
      method: "POST",
      // method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        authenticity_token: token.content,
        collection_id: window.collectionId,
        event_id: window.eventId,
        selected_seats: this.selectedSeats,
        payment_status: 'pending',
        // ticket_type: window.ticketType.innerText
      }),
    });
    const response = await saveSeat.json();
    if (response.status === "unprocessable_entity") {
      alert("Something went wrong while saving your seats");
    } else {
      const redirectURL = `${window.location.pathname}?address=${window.collectionId}`;
      window.location.href = redirectURL;
    }
  }
}

window.addEventListener("DOMContentLoaded", async () => {
  window.totalToSelect = document.getElementById("total-to-select");
  window.totalTicket = document.getElementById('total-ticket');
  window.checkoutBtn = document.getElementById("checkout-btn");
  window.checkoutModal = document.getElementById('checkout-modal');
  window.seatingExecBtn = document.getElementById("seating-exec-btn");
  window.seatingModal = document.getElementById("seating-modal");
  window.qtyInput = document.getElementById("qty-input")?.value || 0;
  window.ownedTickets = document.getElementById('owned-tickets')?.value || 0;
  window.maxQty = document.getElementById('max-qty')?.value;
  window.ticketType = document.getElementById('collection-seating-type')?.value;
  window.displayTicketType = document.getElementById('ticket-type');
  window.originCollections = JSON.parse(document.getElementById('list-collection-after-filter')?.value);
  window.seatingModalCloseBtn = document.getElementById(
    "seating-modal-close-btn"
  );
  window.collectionId = document.getElementById('collection-seating-address')?.value;
  window.eventId = document.getElementById('event-seating-id')?.value;

  window.checkoutBtn?.addEventListener("click", () => {
    if (qtyInput.value < window.seatSelection.selectedSeats.length){
      alert('Selected seats are more than number of tickets');
      return
    }
    if(window.maxQty < window.seatSelection.selectedSeats.length + ownedTickets){
      toastr.error(`You can only buy ${window.maxQty - ownedTickets} ticket(s) for this event`);
      return
    }
    if (window.checkoutBtn.classList.contains("disabled")) return;
    window.seatSelection.submitSeats();
    return false
  });

  window.seatingExecBtn?.addEventListener("click", async () => {
    if(window.qtyInput.value === '' || window.qtyInput.value < 1){
      return toastr.error('Please enter quantity');
    }
    // window.Ticket.setMaximumSelection(window.qtyInput.value);
    let iframe = $("#seats-map-iframe");
    let url = iframe.data("src");
    let originalUrl = iframe.data("originalUrl")
    let ticketTypes = window.originCollections.map((item) => item.data.event_ticket_type);

    window.seatingModal.classList.add("active");

    const fetchMaxQty = async (retries = 3) => {
      console.log('called', retries);
      if (retries === 0) {
        alert('Unable to fetch data after multiple attempts. Please try again later.');
        return;
      }
      await new Promise(resolve => setTimeout(resolve, 1500));

      await $.ajax({
        url: `${window.location.pathname}?address=${window.originCollections[0].data.address}`,
        type: "GET",
        dataType: "json",
        success: function (data) {
          if (data) {
            window.maxQty = data?.max_ticket_quantity;
            window.totalTicket.innerText = data?.max_ticket_quantity;
          } else {
            return fetchMaxQty(retries - 1);
          }
        },
        error: function () {
          return fetchMaxQty(retries - 1);
        },
      });
    };
    try {
      await fetchMaxQty();
      url += `?collection_id=${collectionId}&event_id=${eventId}&quantity=${window.maxQty}&ticket_type=${ticketTypes}&original_url=${originalUrl}`
      iframe.attr("src" , url);
    } catch (error) {
      console.error(error.message);
      return;
    }
  });

  window.seatingModalCloseBtn?.addEventListener("click", () => {
    window.seatingModal.classList.remove("active");
  });

  window.addEventListener('message', async (event) =>  {
    if (event.data && event.data.type === 'confirmSelectedSeats') {
      console.log(event.data.selectedSeats);
      let iframe = $("#seats-map-iframe");
      let url = iframe.data("src");
      let originalUrl = iframe.data("originalUrl");
      window.seatSelection.setSelectedSeats(event.data.selectedSeats);

      if (event.data.selectedSeats.length === 0) {
        ticketType = handleNoSeatSelected(window.originCollections.map((item) => item.data.event_ticket_type));
      } else {
        ticketType = handleSeatsSelected();
      }
      updateUrl(url, iframe, originalUrl, ticketType, window.seatSelection.selectedSeats);
    }
  });
})

const handleNoSeatSelected = (ticketTypes) => {
  window.checkoutBtn.classList.add("disabled");
  window.displayTicketType.innerText = '';
  window.totalToSelect.innerText = 0;
  window.collectionId =  window.originCollections[0].data.address;
  return ticketTypes;
}

const handleSeatsSelected = () => {
  let prefixSelectedSeats = window.seatSelection.selectedSeats[0].split('-')[0] + '-' + window.seatSelection.selectedSeats[0].split('-')[1]
  const matchingCollection = window.originCollections.filter((item) => item.data.event_ticket_type === ticketTypePrefix[prefixSelectedSeats]);
  let ticketType = matchingCollection[0].data.event_ticket_type;

  window.totalToSelect.innerText = window.seatSelection.selectedSeats.length;
  window.displayTicketType.innerText = matchingCollection[0].data.event_ticket_type;
  window.collectionId = matchingCollection[0].data.address;
  return ticketType;
}

const updateUrl = (url, iframe, originalUrl, ticketType, selectedSeats) => {
  url += `?collection_id=${window.collectionId}&event_id=${window.eventId}&quantity=${window.maxQty}&ticket_type=${ticketType}&original_url=${originalUrl}&current_selected_seats=${selectedSeats}`
  iframe.attr("src" , url);
};