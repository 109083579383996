import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form/dist/index';

export const ProfileModalContainer = ({
  user_attachment,
  closeModal,
  open,
  handleDeleteAttachment,
  updateUser,
  user,
  translations
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  const onSubmit = () => {
    setLoading(false);
    let formUser = new FormData();
    if (selectedFile) {
      formUser.append('crop_attachment_image', selectedFile);
    }
    formUser.append('type', 'attachment');
    const tokenn = document.querySelector('[name=csrf-token]');

    updateUser(formUser, `${tokenn.content}`, user.id)
      .then((res) => {
        toastr.success(res.message);
        setLoading(false);
        closeModal();
        location.reload();
      })
      .catch((err) => {
        toastr.error(err.message);
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div
      className='upload_modal place-content-center'
      style={{ display: open ? 'grid' : 'none' }}
    >
      <div className='relative grid grid-cols-1 gap-4 justify-items-center modals-container'>
        <h4 className='modal-headers'>{translations.upload_profile_photo}</h4>
        <button
          type='button'
          onClick={closeModal}
          className='absolute text-black top-2 right-2'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M6 18L18 6M6 6l12 12'
            />
          </svg>
        </button>
        <div className='h-[120px] w-[120px] rounded-full relative'>
          <div
            id='imageModalPreview'
            style={{
              backgroundImage: `url(${user_attachment})`,
              backgroundPosition: 'center center',
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
            }}
            className='h-[120px] w-[120px] rounded-full object-cover z-0'
          >
            <input
              type='file'
              name='attachment'
              onChange={changeHandler}
              className='hidden'
              id='imageUpload'
            />
          </div>

          <label
            htmlFor='imageUpload'
            className='z-20 cursor-pointer icon-camera'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='w-4 h-4 text-white'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z'
              />
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z'
              />
            </svg>
          </label>
        </div>

        <div className="mt-2 grid grid-cols-1 !gap-1 w-full text-center font-semibold" style={{color:'#0800ff'}}>{translations.recommended_image_size}</div>

        <div className='mt-2 grid grid-cols-1 !gap-1 w-full'>
          <button
            type='button'
            onClick={handleDeleteAttachment}
            className='mb-3 modal-button-red  modals-btn w-full cursor-pointer !z-50'
          >
            {translations.delete_image}
          </button>
          <button
            type='button'
            onClick={onSubmit}
            disabled={loading}
            className='w-full modal-button-dark modals-btn'
          >
            {/* {loading && <i className='fa fa-spinner fa-pulse'></i>} */}
            {translations.save}
          </button>
        </div>
      </div>
    </div>
  );
};
