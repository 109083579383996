import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["photosContainer", "video", "playButton"];
  connect() {
    console.log("Hello manage photo controller!");
  }
  
  photosContainerTargetConnected() {
    new Sortable(this.photosContainerTarget, {
      animation: 150,
      handle: ".object-cover",
      onUpdate: function (evt) {
        const sortedIDs = [];
        evt.from.querySelectorAll("[data-image-id]").forEach((item) => {
          sortedIDs.push(item.getAttribute("data-image-id"));
        });
        document.getElementById("sorted_image_ids").value = sortedIDs.join(",");
      },
      onEnd: (evt) => {
        this.updatePhotoOrder();
      },
    });
  }

  updatePhotoOrder() {
    const photoLabels = this.element.querySelectorAll(".idxPhoto");
    photoLabels.forEach((label, index) => {
      label.innerText = `Photo ${index + 1}`;
    });
  }

  appendPhotos(event) {
    const files = event.target.files;
    if (files && files.length > 0) {
      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const src = e.target.result;
          const photoContainer = document.createElement("div");
          photoContainer.className = "col-12 col-sm-6 col-md-4 col-lg-3 mb-3";
  
          if (file.type.startsWith('video')) {
            const video = document.createElement("video");
            video.src = src;
            video.className = "object-cover";
            video.style.width = "150px";
            video.style.height = "150px";
            video.muted = true;
            video.setAttribute('controls', true);
            photoContainer.appendChild(video);
          } else {
            const img = document.createElement("img");
            img.src = src;
            img.className = "object-cover rounded-lg";
            img.style.height = "150px";
            img.style.width = "150px";
            img.alt = "Uploaded Photo";
            photoContainer.appendChild(img);
          }
  
          const photoLabel = document.createElement("div");
          let photoLabels = document.querySelectorAll(".idxPhoto");
          let lastNumber = photoLabels.length ? parseInt(photoLabels[photoLabels.length - 1].innerText.replace("Photo ", "")) : 0;
          photoLabel.className = "text-center idxPhoto";
          photoLabel.innerText = `Photo ${lastNumber + 1}`;
          photoContainer.appendChild(photoLabel);
          this.photosContainerTarget.appendChild(photoContainer);
        };
        reader.readAsDataURL(file);
      });
      this.initSortable();
    }
  }
  
  play(event) {
    let buttonIndex = this.playButtonTargets.indexOf(event.currentTarget);
    let video = this.videoTargets[buttonIndex];
    video.play();
    video.setAttribute('controls', '');
    event.currentTarget.style.display = 'none';
  }
}
