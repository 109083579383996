import React from "react";
import ReactDOM from "react-dom";
import Select from "react-select";
import { Controller } from "stimulus";
export default class extends Controller {
  connect(){
    console.log('Hello from select controller')
  }
  async openSeat(e) {
    const seatElement = e.target.closest(".seat");
    const collectionAddress = seatElement.dataset.collectionAddress;
    const iframe = $("#seats-map-iframe");
    let url = iframe.data("src");
    let originalUrl = iframe.data("originalUrl")
    const ticketType = window.originCollections.filter((item) => item.data.address === collectionAddress)[0].data.event_ticket_type;
    console.log(ticketType)
    window.seatingModal.classList.add("active");
    await $.ajax({
      url: `${window.location.pathname}?address=${collectionAddress}`,
      type: "GET",
      dataType: "json",
      success: function (data) {
        if (data) {
          window.maxQty = data?.max_ticket_quantity;
          window.totalTicket.innerText = data?.max_ticket_quantity;
        } else {
          alert('Unable to fetch data. Please try again later.');
          return;
        }
      },
    });
    url += `?collection_id=${collectionAddress}&event_id=${window.eventId}&quantity=${window.maxQty}&ticket_type=${ticketType}&original_url=${originalUrl}&is_open_seat=true`
    iframe.attr("src" , url);
  }
}