import { Controller } from "stimulus";
import Swiper, { Autoplay, Navigation, Pagination } from "swiper";

export default class extends Controller {
  static targets = ["info"];

  connect() {
    this.initializeSwiper();
  }

  initializeSwiper() {
    let savedMySwiperIndex = 0
    let savedTeamSwiperIndex = 0
    let savedMatchDaySwiperIndex = 0
    let savedMySwiperGalaIndex = 0
    if (window.location.pathname.includes('collectibles_category')) {
      savedMySwiperIndex = this.getSafeSlideIndex("savedMySwiperIndex", ".mySwiper .swiper-slide");
      savedTeamSwiperIndex = this.getSafeSlideIndex("savedTeamSwiperIndex", ".teamSwiper .swiper-slide");
      savedMatchDaySwiperIndex = this.getSafeSlideIndex("savedMatchDaySwiperIndex", ".matchDaySwiper .swiper-slide");
      savedMySwiperGalaIndex = this.getSafeSlideIndex("savedMySwiperGalaIndex", ".mySwiperGala .swiper-slide");
    }

    Swiper.use([Navigation, Autoplay, Pagination]);
    this.mySwiper = new Swiper(".swiper-container", {
      // Optional parameters
      spaceBetween: 15,
      slidesPerView: 1.04,
      freeMode: true,
      speed: 500,
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        440: {
          slidesPerView: 1.3,
          spaceBetween: 20,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 2.08,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3.08,
          spaceBetween: 20,
        },
        1600: {
          slidesPerView: 4.1,
          spaceBetween: 25,
        },
      },
    });
    new Swiper(".heroSwiper", {
      slidesPerView: 1,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      loop: true,
      loopFillGroupWithBlank: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    new Swiper(".photoGallerySwiper", {
      slidesPerView: 1,
      navigation: {
        nextEl: '.triangle-right',
        prevEl: '.triangle-left',
      },
      breakpoints: {
        // when window width is >= 320px
        0: {
          slidesPerView: 1,
          spaceBetween: 40,
        },
        440: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
      },
      pagination: {
        el: '.pagination',
        clickable: true,
      },
    });

    new Swiper(".endUserPhotoGallerySwiper", {
      slidesPerView: 1,
      navigation: {
        nextEl: '.triangle-right',
        prevEl: '.triangle-left',
      },
      breakpoints: {
        // when window width is >= 320px
        0: {
          slidesPerView: 1,
          spaceBetween: 40,
        },
        440: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
      },
      pagination: {
        el: '.pagination',
        clickable: true,
      },
    });

    const mySwiperGala = new Swiper(".mySwiperGala", {
      slidesPerView: 3,
      loop: true,
      initialSlide: savedMySwiperGalaIndex,
      loopFillGroupWithBlank: true,
      slidesOffsetAfter: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        0: { slidesPerView: 1, spaceBetween: 40 },
        440: { slidesPerView: 1, spaceBetween: 20 },
        640: { slidesPerView: 1, spaceBetween: 20 },
        700: { slidesPerView: 2, spaceBetween: 20 },
        1024: { slidesPerView: 3, spaceBetween: 120},
        1524: { slidesPerView: 3, spaceBetween: 150},
        1700: { slidesPerView: 3, spaceBetween: 190},
      },
    });
    const mySwiper = new Swiper(".mySwiper", {
      slidesPerView: 4,
      spaceBetween: 15,
      loop: true,
      initialSlide: savedMySwiperIndex,
      loopFillGroupWithBlank: false,
      slidesOffsetAfter: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        // when window width is >= 320px
        0: {
          slidesPerView: 1,
          spaceBetween: 40,
        },
        440: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },

        700: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15,
        },
      },
    });
    const teamSwiper = new Swiper(".teamSwiper", {
      slidesPerView: 4,
      spaceBetween: 15,
      loop: true,
      initialSlide: savedTeamSwiperIndex,
      loopFillGroupWithBlank: false,
      slidesOffsetAfter: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        0: { slidesPerView: 1, spaceBetween: 40 },
        440: { slidesPerView: 1, spaceBetween: 20 },
        640: { slidesPerView: 1, spaceBetween: 20 },
        700: { slidesPerView: 2, spaceBetween: 20 },
        1024: { slidesPerView: 4, spaceBetween: 15 },
      },
    });
    const matchDaySwiper = new Swiper(".matchDaySwiper", {
      slidesPerView: 4,
      spaceBetween: 15,
      loop: true,
      initialSlide: savedMatchDaySwiperIndex,
      loopFillGroupWithBlank: false,
      slidesOffsetAfter: 0,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        // when window width is >= 320px
        0: {
          slidesPerView: 1,
          spaceBetween: 40,
        },
        440: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        700: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15,
        },
      },
    });

    mySwiperGala.on("slideChange", function () {
      localStorage.setItem("savedMySwiperGalaIndex", mySwiperGala.realIndex);
    });
    mySwiper.on("slideChange", function () {
      localStorage.setItem("savedMySwiperIndex", mySwiper.realIndex);
    });
    teamSwiper.on("slideChange", function () {
      localStorage.setItem("savedTeamSwiperIndex", teamSwiper.realIndex);
    });
    matchDaySwiper.on("slideChange", function () {
      localStorage.setItem("savedMatchDaySwiperIndex", matchDaySwiper.realIndex);
    });
  }

  getSafeSlideIndex(storageKey, slideSelector) {
    const savedIndex = parseInt(localStorage.getItem(storageKey)) || 0;
    const slideCount = document.querySelectorAll(slideSelector).length;

    if (savedIndex >= slideCount) {
      localStorage.setItem(storageKey, "0");
      return 0;
    }
    return savedIndex;
  }

  desc(e) {
    const desc = document.querySelector(".text-desc");
    desc.innerText = e.currentTarget.dataset.description;
    // console.log(e.currentTarget.dataset.description)
    // alert(this.infoTarget.innerText)
  }

  descHide() {
    alert("Hello");
    this.infoTarget.innerText = "";
  }
}
