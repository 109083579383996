import { Controller } from "stimulus"

// Connects to data-controller="user-tickets"
export default class extends Controller {
  static targets = ["panel"]
  connect() {
  }

  initialize() {
    this.showPanel()
    this.truncateText()
  }

  showPanel() {
    setTimeout(() => {
      this.element.style.display = "block"
    }, 4000);
  }

  close() {
    this.panelTarget.style.display = "none"
  }

  truncateText(){
    const desc = document.querySelectorAll('.desc_digital');
    for (let i = 0; i < desc.length; i++) {
      const text = desc[i].innerText;
    if (text.length > 140) {
      const truncatedText = text.slice(0, 141) + '...';
      desc[i].textContent = truncatedText;
    }
  }
    
  }

}
