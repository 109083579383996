import ApplicationController from './application_controller'
import axios from 'axios';
import Notiflix from "notiflix";


// Connects to data-controller="tickets"
const CustomApi = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": true,
  },
});
export default class extends ApplicationController {
  static targets = ["issue", 'subscriber', 'download', 'availableTicket', 'userName', 'eventName', 'eventDate']

  connect () {
    super.connect()
    // add your code here, if applicable
  }

  initialize() {
    this.issueTicket()
    this.timer(10);
  }


  issueTicket() {
    const issueTabContent = document.getElementById("issue-ticket")
    if(issueTabContent) issueTabContent.style.display = "block"
  }

   async checkMolaSub(email, id, collectionId, token, path, molaSubRequired){
    console.log(path)
    let isMolaSub;
    const collection = document.getElementById("collection-id")
    Notiflix.Loading.arrows('')
    const response = await fetch(
      `https://api2-mola.onwards.pro/v1/subscriber/queryuser?email=${email}`,
      {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type":
            "application/json",
          Accept: "application/json",
          Authorization:
            "Basic YWZmaWxpYXRlQG1vbGEudHY6TW9sYTIwMjI=",
        },
      }
    );
    let result = response.json();
    let activeSub = await result;
    if (activeSub.activeSubscription) {
      isMolaSub = true;
    } else {
      isMolaSub = false;
    }


    const userUpdate = await axios.put(
      `/update-user-mola-subscription/${id}`,
      {
        authenticity_token:
          token.content,
        mola_sub: isMolaSub,
      }
    );

    if((userUpdate.status === 200 && isMolaSub) || (userUpdate.status === 200 && molaSubRequired == '0')){
      console.log(123);
      Notiflix.Loading.remove()
      if(path === 'tickets'){
        window.location.href = `/collections/${collectionId}/issue_tickets`
      } else if (path === 'crypto'){
        window.show_modal('#Buy-modal')
      } else {
        let qty = document.getElementById('buy_qty');
        if(qty.value){
          window.location.href = `/collections/${collection.value}/set-quantity?quantity=${qty.value}`
        } else {
          window.location.href = `/collections/${collection.value}/set-quantity`
        }
      }

    } else {
      Notiflix.Loading.remove()
      window.show_modal('#molaSubscriber');
    }
  }

  async issueSeating() {
    const url = window.location.href;
    await CustomApi.get(`collections/${url.split("/")[4]}/customer-seating-map`)
  }

  close() {
    history.back()
  }

  revoke() {
    // this.stimulate('Tickets#revoke')
    console.log('hello')
  }

  async issueCollectionTicket(e) {
    e.preventDefault();
    const user = document.getElementById('user-email').value;
    const userId = document.getElementById('user-id').value;
    const molaSubRequired = '0';
    const collectionId = e.currentTarget.dataset.collectionId;
    const token = document.querySelector("[name=csrf-token]");
    this.checkMolaSub(user, userId, collectionId, token, 'tickets', molaSubRequired)
  }

  async issueCollectionTicketShow(e) {
    e.preventDefault();
    // let userE = 'peterzhang037@yopmail.com'
    const user = document.getElementById('email').value;
    const userId = document.getElementById('id').value;
    const molaSubRequired = '0';
    const token = document.querySelector("[name=csrf-token]");
    const collectionId = window.location.pathname.split('/')[2]
    this.checkMolaSub(user, userId, collectionId, token, 'tickets', molaSubRequired)
  }

  async buyWithoutCrypto(e) {
    console.log(123);
    e.preventDefault()
    const user = document.getElementById('owner-email').value;
    const id = document.getElementById('owner-id').value;
    const molaSubRequired = document.getElementById('mola-sub-required').value;
    const token = document.querySelector("[name=csrf-token]");
    const collectionId = window.location.pathname.split('/')[2]
    this.checkMolaSub(user, id, collectionId, token, 'without crypto', molaSubRequired)
  }

  async buyWithCrypto(e) {
    e.preventDefault()
    const user = document.getElementById('owner-email').value;
    const id = document.getElementById('owner-id').value;
    const molaSubRequired = document.getElementById('mola-sub-required').value;
    const token = document.querySelector("[name=csrf-token]");
    const collectionId = window.location.pathname.split('/')[2]
    this.checkMolaSub(user, id, collectionId, token, 'crypto', molaSubRequired)
  }

  downloadCollection(){
    var element = this.downloadTarget
    const userName = this.userNameTarget.innerText;
    const eventDate = this.eventDateTarget.innerText;
    const eventName = this.eventNameTarget.innerText;
    element.style.height = '100%';
    element.style.width = '570px';
    element.style.margin = '0 auto';
    element.style.marginTop = '10px';
    element.style.backgroundColor = 'transparent';
    var opt = {
        filename:     `${eventName.split(' ').join('-')}_${userName.split(' ').join('-')}_${eventDate}.pdf`,
        image:        { type: 'jpg', quality: 4 },
        jsPDF:        { unit: 'mm', format: [148, 290], x: '20px', orientation: 'portrait', precision: '12', compress: true, }
      };

    html2pdf().set(opt).from(element).save();
  }

  timer(t){
    const countDiv = document.getElementById('timer');
    const address = document.getElementById('address');
    let countdown = t * 60 * 1000;
    var timerId = setInterval(async () => {
      countdown -= 1000;
      var min = Math.floor(countdown / (60 * 1000));
      var sec = Math.floor((countdown - (min * 60 * 1000)) / 1000);
      if (min < 10){
        min = '0' + min
      }
      if (sec < 10){
        sec = '0' + sec
      }
      if (countdown <= 0) {
         clearInterval(timerId);
         const paymentIntent = document.getElementById('stripe_payment_intent').dataset.paymentIntent;
         await axios.get('/payment_intent_expired?payment_intent=' + paymentIntent)
         if(address.value){
           window.location.href = `/collections/${address.value}`;
         }
      } else {
         $("#timer").html(min + " : " + sec);
      }

    }, 1000);
  }

  increaseQty(){
    let qty = document.getElementById('buy_qty');
    let newQty = document.getElementById('new-ticket-qty');
    let amountDisplay = document.getElementById('qtyAmt');
    const amount = document.getElementById('new-amount');
    const total = document.getElementById('totalAmt');
    const category = document.getElementById('category');
    const maxQty = document.getElementById('max-qty');

    if(qty.value !== '' && +qty.value >= 0 && +qty.value < +maxQty.value){
      let newNum = (parseFloat(qty.value) + 1).toFixed(2);
      let product = (newNum * parseFloat(amount.value)).toFixed(2);
      if(category.value === 'true'){
        newQty.innerText = `${parseInt(newNum)} Tickets`
      }else {
        newQty.innerText = `Quantity ${parseInt(newNum)}`
      }

      amountDisplay.innerText = product;
      total.innerText = product;
      return qty.value = parseInt(newNum);
    }
  }

  decreaseQty(){
    let qty = document.getElementById('buy_qty');
    let newQty = document.getElementById('new-ticket-qty');
    let amountDisplay = document.getElementById('qtyAmt');
    const amount = document.getElementById('new-amount');
    const total = document.getElementById('totalAmt');
    const category = document.getElementById('category');
    if(qty.value !== '' && +qty.value >= 2){
      let newNum = (parseFloat(qty.value) - 1).toFixed(2);
      let product = (newNum * parseFloat(amount.value)).toFixed(2)
      if(category.value === 'true'){
        if(+qty.value == 1){
          newQty.innerText = `${parseInt(newNum)} Ticket`
        } else {
          newQty.innerText = `${parseInt(newNum)} Tickets`;
        }
      } else {
          newQty.innerText = `Quantity ${parseInt(newNum)}`
      }
      amountDisplay.innerText = product;
      total.innerText = product;
      return qty.value = parseInt(newNum);
    }
  }
  changeQty(e){
    e.preventDefault();
    let newQty = document.getElementById('new-ticket-qty');
    let amountDisplay = document.getElementById('qtyAmt');
    const amount = document.getElementById('new-amount');
    const total = document.getElementById('totalAmt');
    let product = parseInt(e.target.value) * parseInt(amount.value)
    if(e.target.value === 1){
        newQty.innerText = `${e.target.value} Ticket`;
      }
    newQty.innerText = `${e.target.value} Tickets`;
    amountDisplay.innerText = product;
    total.innerText = product;
  }

async validateInput(e){
    const dupErrorMsg = document.getElementById('error_msg_display');
    const ticketArray = document.getElementById('allTickets');
    const allId = document.querySelectorAll('.doc-number');
    let inputs = []
    for(let i = 0; i < allId.length; i++){
      if(allId[i].value !== ''){
        inputs.push(allId[i].value)
      }
    }

    for(let i = 0; i < inputs.length; i++){
      if(ticketArray.value.includes(inputs[i])){
        dupErrorMsg.innerText = `ID number ${inputs[i]} already exists for this event`
        e.preventDefault()
        break;
      }
      if(inputs[i].length < 8 || inputs[i].length > 16){
        dupErrorMsg.innerText = 'ID Document Number must be bigger than 8 characters and smaller than 16 characters'
        e.preventDefault();
        break;
      }
      if(inputs.length !== new Set(inputs).size){
        dupErrorMsg.innerText = `ID document number must be unique. Kindly check!`
        e.preventDefault();
        break;
      }
    }
  }

  validateId(e){
    e.preventDefault();
    const value = e.target.value
    const ticketArray = document.getElementById('allTickets');
    const displayErrorMsg = document.getElementById('error_msg_display');
    const allId = document.querySelectorAll('.doc-number');
    displayErrorMsg.innerText = ''
    let arr = []
    for(let i = 0; i < allId.length; i++){
      if(allId[i].value !== ''){
       arr.push(allId[i].value)
      }
    }

    if(value.length < 8 || value.length > 16) {
      displayErrorMsg.innerText = 'ID Document Number must be bigger than 8 characters and smaller than 16 characters'
    }

    if(ticketArray.value.includes(value)){
      displayErrorMsg.innerText = 'ID document number already exists for this event';
    }

    if(arr.length !== new Set(arr).size){
      displayErrorMsg.innerText = 'ID document number must be unique';
    }
  }

  // readURL(e){
  //   const preview = document.getElementById('img_prev_0')
  //   const banner = document.getElementById('myFile_0');
  //   var fReader = new FileReader();
  //   fReader.readAsDataURL(banner.files[0]);
  //   fReader.onloadend = function(event){
  //     preview.classList.remove('d-none')
  //     preview.src = event.target.result;
  //   }
  // }

  readURL(e){
    const number = this.availableTicketTarget.value
    for(let i = 0; i < number; i++){
      const preview = document.getElementById(`img_prev_${i}`)
      const banner = document.getElementById(`myFile_${i}`);
      var fReader = new FileReader();
      fReader.readAsDataURL(banner.files[0]);
      fReader.onloadend = function(event){
        preview.classList.remove('d-none')
          preview.src = event.target.result;
      }
    }
  }

  reduceQty(){
    const qtyNum = document.getElementById('qtyNum');
    const availableTicket = document.getElementById('available');
    const num = document.getElementById('qty-num-reduce');
    const form = document.getElementById('form')
    const formContainer = document.getElementById('formContainer');
    const errMsg = document.getElementById('error_msg_display');
    if(+num.innerText - 1 > 0){
       errMsg.innerText = '';
      formContainer.innerHTML = '';
      num.innerText = +num.innerText - 1
      form.append(formContainer);
      this.getForm(Number(num.innerText))
    }
  }

  increaseQtyBtn(){
    const availableTicket = document.getElementById('available');
    const num = document.getElementById('qty-num-reduce');
    const form = document.getElementById('form')
    const formContainer = document.getElementById('formContainer');
    const errMsg = document.getElementById('error_msg_display');
    if(+num.innerText + 1 <= Number(availableTicket.value)){
      errMsg.innerText = ''
      formContainer.innerHTML = '';
      num.innerText = +num.innerText + 1
      form.append(formContainer);
      this.getForm(Number(num.innerText))
    } else {
      errMsg.innerText = `You can only issue ${availableTicket.value} ticket(s)`
    }
  }

  getForm(num){
    const formContainer = document.getElementById('formContainer');
    const form = document.getElementById('form');
    for (let i = 0; i < num; i++) {
      const para = document.createElement('p');
      para.classList.add('d-none');
      para.setAttribute('id', `error_msg_dup_${i}`);
      formContainer.append(para);
      const mainDiv = document.createElement('div');
      mainDiv.classList.add('formContainer');
      const fullName = document.createElement('input');
      fullName.setAttribute('type', 'text');
      fullName.setAttribute('name', `ticket[fullname_${i}]`);
      fullName.setAttribute('id', `ticket_fullname_${i}`);
      fullName.setAttribute('placeholder', `Ticket Holder Name ${i + 1}`);
      fullName.setAttribute('required', true);
      fullName.classList.add('text-input');
      mainDiv.append(fullName);

      const idNum = document.createElement('input');
      idNum.setAttribute('type', 'text');
      idNum.setAttribute('name', `ticket[ind_ktp_no_${i}]`);
      idNum.setAttribute('id', `ticket_id_no_${i}`);
      idNum.setAttribute('placeholder', 'Your ID number');
      idNum.setAttribute('required', true);
      idNum.classList.add('text-input', 'id-doc-num');
      idNum.addEventListener('keyup', this.validateId);
      mainDiv.append(idNum);

      const label2 = document.createElement('label');
      label2.classList.add('mt-2');
      label2.setAttribute('for', `myFile_${i}`);

      const labelDiv = document.createElement('div');
      labelDiv.classList.add('upload-id');
      label2.append(labelDiv);
       
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const svgPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      svg.setAttribute('fill', 'w-[#153B65]');
      svg.setAttribute('viewBox', '0 0 24 24');
      svg.setAttribute('stroke', 'currentColor');
      svg.setAttribute('stroke-width', '1.5');
      svg.classList.add('w-6', 'h-6', 'text-white');
      
      svgPath.setAttribute(
        'd',
        'M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
      );
      svgPath.setAttribute('stroke-linecap', 'round');
      svgPath.setAttribute('stroke-linejoin', 'round');
      svg.append(svgPath);
      labelDiv.append(svg);

      const idDiv = document.createElement('div');
      idDiv.classList.add('doc-id', 'text-[#153B65]');
      idDiv.innerText = 'ID';
      labelDiv.append(idDiv);
      mainDiv.append(label2);

      const docImage = document.createElement('img');
      docImage.classList.add('w-full', 'd-none');
      docImage.style.height = '40px';
      docImage.style.width = '55px';
      docImage.setAttribute('id', `img_prev_${i}`);
      docImage.setAttribute('src', '');
      mainDiv.append(docImage);

      const idImg = document.createElement('input');
      idImg.setAttribute('type', 'file');
      idImg.setAttribute('name', `ticket[ind_ktp_id_image_${i}]`);
      idImg.setAttribute('id', `myFile_${i}`);
      idImg.setAttribute('accept', 'image/png, image/jpeg, image/jpg, image/bmp');
      idImg.setAttribute('required', true);
      idImg.classList.add('d-none');
      mainDiv.append(idImg);
      idImg.addEventListener('change', function(e){
        const preview = document.getElementById(`img_prev_${i}`)
        const banner = document.getElementById(`myFile_${i}`);
         var fReader = new FileReader();
         fReader.readAsDataURL(banner.files[0]);
         fReader.onloadend = function(event){
           preview.classList.remove('d-none')
           preview.src = event.target.result;
        }
      });

      formContainer.append(mainDiv);
      form.append(formContainer);
    }
  }

  increaseCartQty(){
    let currentQty = document.getElementById('buyCartQty');
    let cartAmount = document.getElementById('cartAmount');
    let itemPrice = document.getElementById('itemPrice');
    let actualPrice = cartAmount.innerText;
    const newQty = +(currentQty.innerText) + 1;
    currentQty.innerText = newQty;
    cartAmount.innerText =  (newQty * +actualPrice / (newQty - 1)).toFixed(2)
    itemPrice.value = newQty
  }

  decreaseCartQty(){
    let currentQty = document.getElementById('buyCartQty');
    let cartAmount = document.getElementById('cartAmount');
    let itemPrice = document.getElementById('itemPrice');
    let actualPrice = cartAmount.innerText;
    if(+currentQty.innerText > 1){
      const newQty = +(currentQty.innerText) - 1;
      currentQty.innerText = newQty;
      cartAmount.innerText = (newQty * +actualPrice / (newQty + 1)).toFixed(2)
      itemPrice.value = newQty
    }
  }

}
