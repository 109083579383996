import { Controller } from "stimulus";
import Web3Modal from "web3modal";
import Web3 from "web3";
import Notiflix from "notiflix";
import { FILE_PIN_URL, JSON_PIN_URL } from "../utils/constants";
import Factory from "../utils/Factory.json";
import { airdropService } from "../utils/dropToWallet";
import axios from "axios";
const FormData = require('form-data');

import {
  handleFileUpload,
  ipfsFilePrepare,
  // nftUpload,
  ipfsJsonPrepare,
  prepareIpfsMetadata,
  nftUploadForCreate,
} from "../utils/upload";

const CustomApi = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": true,
  },
});

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export default class extends Controller {
  static targets = ["save"];

  connect() {
    this.multiSelect()
  }
  multiSelect() {
    const checkBtn = document.getElementById("third_party_offer");
    const thirdPartyOffer = document.getElementById("third-party");
    const collection = document.getElementById("collection");
    if (checkBtn && checkBtn.checked) {
      collection.style.display = "none";
      thirdPartyOffer.style.display = "block";
    } else {
      collection ? collection.style.display = "block" : null;
      thirdPartyOffer ? thirdPartyOffer.style.display = "none" : null;
    }
  }
  selectCollection(e) {
    const output = [];
    const display = document.getElementById("display");
    const selected = document.querySelector("select-option");
    selected.addEventListener("click", () => {
      console.log("hello");
    });
    output.push(selected);
    display.innerText = output.split(",").join(" ");
  }

  async createAidropNft(e) {
    e.preventDefault();
    const name = document.getElementById("airdrop-collection-name");
    const category = document.getElementById("airdrop-category");
    const ticketType = document.getElementById("airdrop-ticket-type");
    const description = document.getElementById(
      "airdrop-collection-description"
    );
    const symbol = document.getElementById("airdrop-collection-symbol");
    const numberOfCopies = document.getElementById(
      "airdrop-collection-no-of-copies"
    );
    const network = document.getElementById("airdrop-collection-network");
    const banner = document.querySelector('[name="airdrop_collection[banner]"]').files[0];
    const status = document.getElementById("airdrop-public");
    const airdropId = document.getElementById("airdrop-id");
    const token = document.querySelector("[name=csrf-token]");
    const airdrop_wallet = document.getElementById('airdrop_wallet');

    let isPrivate = false;

    if (status && !status.checked) {
      isPrivate = true;
    }
    try {
      let appChainIds = gon.smart_contract;
      console.log(gon)
      console.log(appChainIds)
      const web3Modal = new Web3Modal();

      const connection = await web3Modal.connect();

      let providerInsatnce = new Web3(connection);

      const chainId = await providerInsatnce.eth.net.getId();

      let address;

      let AIRDROP;

      let tx;

      // if ($('body').data('app-env') == 'production') {
      //   appChainIds = [1, 56, 137]
      // } else {
      //   // appChainIds = [80001, 5, 97]
      //   appChainIds = smartContact
      // }

      if (typeof window.ethereum === "undefined") {
        return Notiflix.Report.failure(
          "Connect to a provider/wallet",
          "",
          "Ok"
        );
      } else if (appChainIds.indexOf(chainId) < 0) {
        return Notiflix.Report.failure(
          "Invalid Network",
          "The Connected Network Is Invalid Or Not Supported",
          "Switch Network",
          function cb() {
            // openModal();
          }
        );
      }

      if (window?.ethereum?.selectedAddress === undefined) {
        return Notiflix.Report.failure(
          "Session Expired",
          "You are not login. Please reload!",
          "Reconnect",
          function cb() {
            //do something
          }
        );
      }

      const imgFile = banner;

      let imageData = new FormData();

      let image_hash;

      imageData.append("file", imgFile);

      const nftFile = ipfsFilePrepare(
        { name: name.value, desc: description.value },
        imageData
      );

      const nftJson = ipfsJsonPrepare({
        name: name.value,
        desc: description.value,
      });

      Notiflix.Loading.arrows("Uploading Airdrop Metadata");

      let metadataUrl = await nftUploadForCreate(FILE_PIN_URL, imageData).then(
        async (response) => {
          const metaDeploy = await prepareIpfsMetadata(
            JSON_PIN_URL,
            response,
            nftJson,
            nftFile.imgPayload
          );

          console.log(response.data.IpfsHash, "image hash");
          image_hash = response.data.IpfsHash;

          return metaDeploy.data.IpfsHash;
        }
      );

      let env;
      if ($('body').data('app-env') == 'production') {
        env = 'production'
      } else {
        env = 'default'
      }

      if(airdrop_wallet.value == 'true') {
        address = Factory.address[env]['autodrop'][chainId];

        AIRDROP = new providerInsatnce.eth.Contract(
          Factory.abi.autodrop,
          `${address}`
        );

        Notiflix.Loading.change("Minting Airdrop Contract");

        tx = await AIRDROP.methods
          .createAirdrop(
            `${name.value}`,
            `${symbol.value}`,
            `${numberOfCopies.value}`,
            `https://gateway.pinata.cloud/ipfs/${metadataUrl}/`
          )
          .send({
            from: window.ethereum?.selectedAddress,
          });
      } else {
        address = Factory.address[env]['airdrop'][chainId];

        AIRDROP = new providerInsatnce.eth.Contract(
          Factory.abi.airdrop,
          `${address}`
        );

        Notiflix.Loading.change("Minting Airdrop Contract");

        tx = await AIRDROP.methods
          .createAirdrop(
            `${numberOfCopies.value}`,
            isPrivate,
            `${name.value}`,
            `${symbol.value}`,
            `https://gateway.pinata.cloud/ipfs/${metadataUrl}/`
          )
          .send({
            from: window.ethereum?.selectedAddress,
          });

      }


      console.log(tx);

      const { tokenAddress } = tx?.events?.AirdropCreated?.returnValues;

      const airdropCollections = new FormData();
      airdropCollections.append("airdrop_collection[name]", name.value);
      airdropCollections.append("airdrop_collection[category]", category.value);
      airdropCollections.append("airdrop_collection[event_ticket_type]", ticketType.value);
      airdropCollections.append("airdrop_collection[description]", description.value);
      airdropCollections.append("airdrop_collection[symbol]", symbol.value);
      airdropCollections.append("airdrop_collection[no_of_copies]", numberOfCopies.value);
      airdropCollections.append("airdrop_collection[network_id]", network.value);
      airdropCollections.append("airdrop_collection[image_hash]", image_hash);
      airdropCollections.append("airdrop_collection[metadata_uri]", metadataUrl);
      airdropCollections.append("airdrop_collection[banner]", banner);
      airdropCollections.append("airdrop_collection[private]", isPrivate);
      airdropCollections.append("airdrop_collection[airdrop_id]", airdropId.value);
      airdropCollections.append("airdrop_collection[nft_contract_address]", tokenAddress);
      Notiflix.Loading.change("Saving Airdrop...");

      await CustomApi.post("admin/airdrop_collections", airdropCollections, {
        headers: {
          'X-CSRF-TOKEN': token.content,
          'Accept': '*/*',
          "Content-Type": "multipart/form-data",
          processData: "false"
        }
      });

      Notiflix.Report.success(
        "Airdrop Created successfully!!!",
        `Token Address ${tokenAddress}`,

        "Continue",
        function cb() {
          if(airdrop_wallet){
            window.location.href = "/admin/airdrop_to_wallets";
          } else {
            window.location.href = "/admin/airdrops";
          }
        }
      );
      // airdropCollections.append("address", tokenAddress);
      // airdropCollections.append("transactionHash", tx?.transactionHash);
    } catch (error) {
      Notiflix.Loading.remove();

      Notiflix.Report.failure("Error while Creating Airdrop!", error.message);

      console.log("error in creating airdrop", error);

      console.log({ ...error });
    }

    console.log(
      name.value,
      description.value,
      banner,
      numberOfCopies.value,
      network.value,
      symbol.value,
      isPrivate,
      airdrop_wallet.value
    );
  }

  async dropToWallets(e) {
    e.preventDefault();
    const contractAddress = document.querySelector('[name="airdrop_whitelist[address]"]');
    console.log(contractAddress)
    // alert('yay')
    // const id = document.getElementById("airdropToMe");
    // const token = document.querySelector('[name=csrf-token]')
    // const network = document.getElementById('airdropNetworkId')
    // let network_id;
    // if (network.value === 'ETH') {
    //   network_id = 1
    // } else if (network.value === 'BSC') {
    //   network_id = 2
    // } else {
    //   network_id = 3
    // }
    try {


      Notiflix.Loading.arrows('Dispatching Airdrop...');


        await airdropService()


        Notiflix.Report.success(
          "NFT Minted Successfully!!!"
          ,
          `All Praise To God!!`,

          "Continue",
          function cb() {
            Notiflix.Loading.remove();
          }
        );

    } catch (error) {
      Notiflix.Loading.remove();

      Notiflix.Report.failure('Error while Creating Airdrop!', error.message);

      console.log('error in creating airdrop', error);

      console.log({...error});
    }
  }
}
