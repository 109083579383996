import { Controller } from "stimulus"
import Decimal from 'decimal.js'
import axios from 'axios';

// Connects to data-controller="collection"
export default class extends Controller {
  static targets = ["eventSelect", "eventTypeTickets", "eventTypeSeasonTicket", "eventTypeMultiTicket", "vat", "inputCustomByEventType"]
  connect() {
    if(this.hasEventSelectTarget){
      this.eventSelectTarget.focus
    }
    const vat = document.getElementById("collection-unlock-vat-description")
    if(vat && vat.value.length > 0) {
      this.vatChange()
    }
    this.selectChange()
  }

  selectChange() {
    this.hiddenAllEventTypeTarget()

    if (!this.hasEventSelectTarget) {
      return
    }

    if (this.eventSelectTarget.value == "Tickets") {
      this.eventTypeTicketsTarget.style.display = "block"
      this.resetValueAllInputByEventType(this.eventTypeTicketsTarget)
    } else if (this.eventSelectTarget.value == "Season Ticket"){
      this.eventTypeSeasonTicketTarget.style.display = "block"
      this.resetValueAllInputByEventType(this.eventTypeSeasonTicketTarget)
    } else if (this.eventSelectTarget.value == "Multi Ticket"){
      this.eventTypeMultiTicketTarget.style.display = "block"
      this.resetValueAllInputByEventType(this.eventTypeMultiTicketTarget)
    } else {
      this.resetValueAllInputByEventType()
    }
  }

  hiddenAllEventTypeTarget(){
    if (this.hasEventTypeTicketsTarget) {
      this.eventTypeTicketsTarget.style.display = "none"
    }
    if (this.hasEventTypeSeasonTicketTarget) {
      this.eventTypeSeasonTicketTarget.style.display = "none"
    }
    if (this.hasEventTypeMultiTicketTarget) {
      this.eventTypeMultiTicketTarget.style.display = "none"
    }
  }

  resetValueAllInputByEventType(parent_element=undefined){
    this.inputCustomByEventTypeTargets.forEach((element) => {
      element.value = ""
      if (parent_element == undefined) {
        element.disabled = true
      } else {
        if (parent_element.contains(element)) {
          element.disabled = false
        } else {
          element.disabled = true
        }
      }
    })
  }

  vatCommonInput() {
    let vat = document.getElementById("collection-unlock-vat-description").value
    const vatText = document.getElementById("price-calc")
    const vatTextTotal = document.getElementById("price-calc-total")
    const ercTokenSelect = document.getElementById("collection_erc20_token_id")
    const instantPriceValue = document.getElementById("instant-price").value
    if(vat.length > 0 && instantPriceValue.length > 0){
      if(vat > 50 || vat.slice(vat.indexOf('.') + 1).length > 2){
        vat = vat.slice(0, vat.length - 1)
      }
      let percentage = Decimal(vat).div(100).times(Decimal(instantPriceValue))
      let calculatedPrice =  Decimal(instantPriceValue).plus(percentage)
      let currentErcToken = ercTokenSelect.options[ercTokenSelect.selectedIndex].text;
      vatText.innerText = `Total price ${instantPriceValue} + ${vat}% VAT`
      vatTextTotal.innerText = `${calculatedPrice} ${currentErcToken}`
    }else{
      vatText.innerText = ""
      vatTextTotal.innerText = ""
    }
  }

  vatChange() {
    let vat = document.getElementById("collection-unlock-vat-description").value
    const vatText = document.getElementById("price-calc")
    const vatTextTotal = document.getElementById("price-calc-total")
    const ercTokenSelect = document.getElementById("collection_erc20_token_id")
    const instantPriceValue = document.getElementById("instant-price-value").value
    const instantPrice = document.getElementById("instant-price")
    if(vat.length > 0 && instantPriceValue.length > 0){
      if(vat > 50 || vat.slice(vat.indexOf('.') + 1).length > 2){
        vat = vat.slice(0, vat.length - 1)
      }
      let percentage = Decimal(vat).div(100).times(Decimal(instantPriceValue))
      let calculatedPrice =  Decimal(instantPriceValue).plus(percentage)
      let currentErcToken = ercTokenSelect.options[ercTokenSelect.selectedIndex].text;
      vatText.innerText = `Total price ${instantPriceValue} + ${vat}% VAT`
      instantPrice.value = `${calculatedPrice}`
      vatTextTotal.innerText = `${calculatedPrice} ${currentErcToken}`
    }else{
      vatText.innerText = ""
      vatTextTotal.innerText = ""
      instantPrice.value = instantPriceValue
    }
  }

  vatInputView() {
    const vat = document.getElementById("collection-unlock-vat-description")
    const vatSection = document.getElementById("unlock-vat-section")
    const auctionCheckButton = document.getElementById('collection_timed_auction_enabled')
    const putOnSale = document.getElementById('collection-put-on-sale')
    const vatCheckButton = document.getElementById('collection-unlock-vat')
    if(vatCheckButton.checked){
      vatSection.classList.remove("hide");
      putOnSale.checked = true;
      if ($('#collection_type').val() == 'single') {
        $("#timedAuction").removeClass("hide")
      }
    }else{
      vatSection.classList.add("hide");
      vat.value = ""
      this.vatChange()
    }
  }

  saleUncheck(e) {
    const vatCheckButton = document.getElementById('collection-unlock-vat')
    if(!e.target.checked) {
      vatCheckButton.checked = e.target.checked;
      this.vatInputView()
    }
  }

  timedAuction() {
    const auctionCheckButton = document.getElementById('collection_timed_auction_enabled')
    const vatCheckButton = document.getElementById('collection-unlock-vat')
    const vatInputField = document.getElementById('unlock-vat-section')
    const vat = document.getElementById("collection-unlock-vat-description")
    if(auctionCheckButton.checked) {
      /* if(vatCheckButton.checked){
        vatCheckButton.checked = false;
        vatInputField.classList.add('hide');
        vat.value = ""
      } */
    }
  }

  voidTickets(e) {
    // const refresh = window.location.reload();
    // if (e.target.id === 'change-price') {
    //   refresh
    //   window.show_modal('#change-price');
    // }
    // if (e.target.id === 'burn-token') {
    //   refresh
    //   window.show_modal('#voidBurnToken')
    // }
    // if (e.target.id === 'transfer-token') {
    //   refresh
    //   window.show_modal('#voidTokenTransfer')
    // }
  }

  itemOption() {
    const checkBtn = document.getElementById("item_option");
    const options = document.getElementById("item-options");
    if (checkBtn && checkBtn.checked) {
      options.style.display = "block";
      console.log(options.style)
    } else {
      options ? options.style.display = "none" : null;
    }
  }
  addNewValue() {
    const container = document.getElementById('newValueContainer');
    const optionDiv = document.createElement('div');
    optionDiv.classList.add("sign__group");
    optionDiv.innerHTML = `<input type="text" name="collection[item_option_values][]" class="sign__input" placeholder="Value (eg Black, XL etc)" />`;
    container.append(optionDiv);
  }
}
